import React from "react";
import "../../Assets/SCSS/promotion.scss";
import { Link } from "react-router-dom";

import Banner from "../../Assets/images/banner1.jpg";
import Poster from "../../Assets/images/20231113_180438.jpg";

const Promotion = () => {
  const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';


  return (    
    <>
    <div style={{ width: '100%' }} className="imgContainerBanner">
      <img src={Banner} />
    </div>
    <section id="promotinal">
      {/* <h4> Renovate now for the chance to</h4>
      <h2>WIN $30,000</h2>
      <h3>BIGGER THAN EVER BEFORE</h3>
      <div className="txtContainer">
        <p>
          We at AGM pride ourselves in offering our customers the BIGGEST AND
          BEST DEALS. Now, we are offering the biggest deal ever!
        </p>
        <p>
          Renovate now for a chance to win $30,000. Every renovation project
          that you do with AGM will get you an additional entry for our CASH
          PRIZE. Minimum renovations value in order to qualify for this draw is
          $15,000 (before taxes).
        </p>
        <p>
          Here’s how you enter: Sign a contract with AGM from April 26, 2023 to
          June 30, 2023 for a renovation valued at a minimum of $15,000 (before
          taxes). The Draw will take place on July 3rd, 2023 at 4:00 p.m.
        </p>
        <p>
          GET YOUR RENOVATIONS DONE RIGHT FOR A CHANCE TO WIN $30,000 CASH <br />
          ONLY with AGM RENOVATIONS – Your Renovation Destination.
        </p>
      </div> */}
      <h4>Renovate now for the chance to</h4>
      <h2>WIN $20,000</h2>
      <h3>We are doing it again.</h3>
      <div className="txtContainer">
        <p>
          We at AGM pride ourselves in offering our customers the BIGGEST AND
          BEST DEALS. Now, we are offering $20,000 Holiday Cash Giveaway.
        </p>
        <p>
          Renovate now for a chance to win $20,000. Every renovation project
          that you do with AGM will get you an additional entry for our CASH
          PRIZE. Minimum renovations value in order to qualify for this draw is
          $15,000 (before taxes).
        </p>
        <p>
          Here’s how you enter: Sign a contract with AGM between November 10th, 2023 and
          January 31st, 2024. The Draw will take place on February 9th, 2024 at 4:00 p.m.
        </p>
        <p>
          GET YOUR RENOVATIONS DONE RIGHT AND GET A CHANCE TO WIN $20,000 CASH<br />
          ONLY with AGM RENOVATIONS – The only Renovation Company trusted by Mike Holmes!
        </p>
      </div>
      <br/>
      <section id="promotinal" className='promoDescription'>
            <h3 style={{    lineHeight: '46px' }}>Don't delay your next projects, <br/>do them now and save BIG.</h3>
            <h5 style={{ fontSize: '1.3em', marginBottom: '20px' }}>2nd project 15% OFF</h5>
            <h5 style={{ fontSize: '1.3em' }}>3rd project 20% OFF</h5>
            <br/>
            <div className="txtContainer">
              <p style={{ fontSize: '1.0em' }}>
              All discounts are applicable only when all projects are booked at the same time (can not be combined with any other promotions/offers)
              </p>
            </div>
          </section>

      <Link style={{ color: 'rgb(6 174 222)', fontSize: '1.2em', marginBottom: '20px', fontWeight: '600', borderBottom: '2px solid rgb(6 174 222)' }} to="/why-agm" >
            WHY AGM?
      </Link>

      <div className="imgContainer">
        {/* <img src={Poster} /> */}
        <video style={{ 
          maxWidth: '980px', width: '90%', 
          borderRadius: '20px', marginBottom: '60px', 
          boxShadow: '2px 2px 10px rgba(0,0,0,.3)' 
        }} src="https://agm-landing.s3.us-east-2.amazonaws.com/websites/other/20240207_142356.mp4" controls />
      </div>

      <Link to="/get-started" className={`blue-btn desktop-btn ${groupColor}`}>
        Free Consultation
      </Link>
    </section>
    </>
  );
};

export default Promotion;
