import React from "react";

import person_1 from "../../Assets/images/founderCeo.jpeg";
import person_2 from "../../Assets/images/operations.jpeg";

export const WhoWeAre = ({ paragraphs }) => {
  return (
    <section className="about-section-2">
      <div className="container">
        <h2>Our Beliefs and Guarantees</h2>
        {paragraphs ? (
          paragraphs
        ) : (
          <p>
            With decades of experience in customer satisfaction – it comes as no
            surprise that we are rated highly on both Google and HomeStars for
            our commitment to excellence. We accredit a large part of our
            success to our top talent and commitment to leadership. We never use
            subcontractors and all our staff are integral members of our team.
            This allows us to facilitate quality control at a grassroots level
            on all aspects of your renovations allowing us to deliver the final
            result on time, within budget and of the utmost quality.
          </p>
        )}

        {/* <div className="founders">

          <div className="box">
            <div className="image">
              <div className="imgContainer">
                <img src={person_1} alt="" />
              </div>
            </div>
            <span className="name">Ivan Atanasov</span>
            <span className="pos">Founder/CEO</span>
          </div>
         </div> */}
          
          {/* <div className="box">
            <div className="image">
              <div className="imgContainer">
                <img src={person_2} alt="" />
              </div>
            </div>
            <span className="name">Elvira Stalteri</span>
            <span className="pos">Operations Manager</span>
          </div> */}
    

      </div>
    </section>
  );
};
